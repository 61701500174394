import { useEffect } from "react";
import { Chip as MUIChip, Container, Grid, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarService from "services/car.service";
import { carActions } from "redux/slices/car";

export default function CarDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const car = useAppSelector((state) => state.car.car);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarService.getCar(id || "");
    return () => {
      dispatch(carActions.setCar(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/cars" title="Back to Cars" />
      <PageHeader title="Car details" />
      {loading && <CircleLoader />}
      {!loading && !car ? (
        <p>Car Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={car?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-sup-car/${car._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <label htmlFor=""> ID</label>
                <p>{car?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Name</label>
                <p>
                  {car?.modelId?.name} - {car?.manufacturingYear}
                </p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Manufacturer</label>
                <p>{car?.modelId?.manufacturer}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Supplier</label>
                <br />
                <Link
                  to={`/supplier-details/${car?.supplierId?._id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {car?.supplierId?.name}
                </Link>
              </div>
            </Grid>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <h4 style={{ margin: 0 }}>Car Pricing</h4>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Daily Rate:</label>
                  <p>{car?.carPricingId?.dailyRate?.toFixed(2)} SAR</p>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Extra KM Cost:</label>
                  <p>{car?.carPricingId?.extraKMCost?.toFixed(2)} SAR</p>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Unlimited KM:</label>
                  <p>{car?.carPricingId?.isUnlimitedKM ? "Yes" : "No"}</p>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Free KM Per Day:</label>
                  <p>{car?.carPricingId?.freeKMPerDay}</p>
                </Stack>
              </div>

              <div className={styles.fieldBox}>
                <h4 style={{ margin: 0 }}>Available on Branches</h4>
                <div>
                  {car?.availableBranchIds?.map((item: any, index: any) => (
                    <Link to={`/branch-details/${item?._id}`}>
                      <MUIChip
                        label={item?.name}
                        key={index}
                        size="small"
                        variant="filled"
                        sx={{ marginRight: "4px" }}
                      />
                    </Link>
                  ))}
                </div>
              </div>

              {car?.includes.length > 0 && (
                <div className={styles.fieldBox}>
                  <h4 style={{ margin: 0 }}>Includes</h4>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '10px' }}>
                    {car?.includes?.map((include: any, index: any) => (
                      <p key={index}>{include},</p>
                    ))}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item md={12} lg={6}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={car?.modelId?.carImage}
                  alt={car?.modelId?.name}
                  style={{ height: "200px", maxWidth: "100%" }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Seats</label>
                <p>{car?.numberOfSeats}</p>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Bags</label>
                <p>{car?.numberOfBags}</p>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Doors</label>
                <p>{car?.numberOfDoors}</p>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Transmission</label>
                <p>{car?.transmission}</p>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Fuel Type</label>
                <p>{car?.fuelType}</p>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Air Conditioned</label>
                <p>{car?.isAirConditioned ? "Yes" : "No"}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
