import { fields, InsServiceOpts } from ".";
import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";

function AddInsuranceForm({ handleSubmit }: any) {
	return (
		<div>
			<form onSubmit={handleSubmit}>
				<ReduxFormFields fields={fields} />
				<Field name="excludes" label="Excludes" component={ComboBoxRedux} multiple={true} freeSolo={false} options={InsServiceOpts} />
				<br />
				<Button variant="contained" type="submit">
					Submit
				</Button>
			</form>
		</div>
	);
}

export default reduxForm({ form: "AddInsuranceForm" })(AddInsuranceForm);
