import { SelectOwnProps } from ".";
import { FormControl, FormHelperText, InputLabel, styled } from "@mui/material";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import CustomSelect from "@mui/material/Select";

export type SelectProps = SelectOwnProps &
  React.ComponentProps<typeof TextField> &
  React.ComponentProps<typeof CustomSelect>;

const BootstrapInput = styled(CustomSelect)(({ theme }) => ({
  backgroundColor: "transparent !important",
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
  "&:focus": {
    backgroundColor: "transparent !important",
  },
  // "&:hover": {
  //   backgroundColor: "transparent !important",
  // },
  "& .Mui-disabled": {
    backgroundColor: "transparent !important",
    opacity: "0.5 !important",
    cursor: "not-allowed",
  },
  "&:after,&:before": {
    display: "none",
  },

  "& .MuiFilledInput-root": {
    backgroundColor: "transparent !important",
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiSelect-filled:not(.Mui-disabled)": {
    backgroundColor: "transparent  !important",
  },
  "& .MuiSelect-filled": {
    borderRadius: 4,
    width: "100%",
    position: "relative",
    border: "1px solid",
    borderColor: "#dbdbdb",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color"]),
    "&:focus": {
      boxShadow: `none`,
      backgroundColor: "none",
      borderColor: "#ed1c24",
    },
  },
  "& .MuiSelect-filled:not(.Mui-disabled):hover": {
    backgroundColor: "transparent !important",
    borderColor: "#ed1c24",
  },
  "& .Mui-focused": {
    backgroundColor: "transparent !important",
    borderColor: "#ed1c24",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  // "& .MuiInputBase-input": {
  //   padding: 0,
  //   backgroundColor: "transparent !important",
  // },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiFilledInput-input:focus": {
    backgroundColor: "transparent !important",
  },
}));

export default function Select({
  disabled,
  disabledOnUpdate,
  options = [],
  ...rest
}: SelectProps) {

  const { id } = useParams();
  const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink htmlFor={`${rest?.id}`}>
        {rest?.label}
      </InputLabel>
      <BootstrapInput
        variant="filled"
        disabled={disabledOnUpdate && id ? true : disabled}
        {...rest}
      >
        {options.map(({ value, label }, index) => (
          <MenuItem
            key={index}
            value={value}
            selected={index === selectedIndex}
            onClick={() => setSelectedIndex(index)}
          >
            {label}
          </MenuItem>
        ))}
      </BootstrapInput>
      {rest?.error && (
        <FormHelperText sx={{ color: "#b72136" }} id="component-error-text">
          {rest?.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
