import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { required } from "utils/validate.util";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import SelectRedux from "components/molecules/SelectRedux";

export { default } from "./AddInsuranceForm";

export const InsServiceOpts = [
  { label: "collision coverage", value: "collision coverage" },
  { label: "all types of tyre puncture", value: "all types of tyre puncture" },
  { label: "theft protection", value: "theft protection" },
  { label: "damage by negligence", value: "damage by negligence" },
  { label: "minor scratches", value: "minor scratches" },
  { label: "mechanical failure", value: "mechanical failure" },
]

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "Status",
    component: SelectRedux,
    cellProps: { sm: 4, md: 4 },
    SelectProps: {
      options: [
        { label: "Active", value: "active" },
        { label: "In Active", value: "in_active" },
      ],
    },
  },
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "info",
    label: "Info",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  // {
  //   name: "excludes",
  //   label: "Excludes",
  //   component: ComboBoxRedux,
  //   cellProps: { sm: 4, md: 4 },
  //   ComboBoxProps: {
  //     multiple: true,
  //     freeSolo: true,
  //     options: InsServiceOpts,
  //   },
  // },
  {
    name: "zero_to_one",
    label: "0 to 100 SAR",
    component: InputRedux,
    cellProps: { sm: 2, md: 2 },
    InputProps: { size: "small" },
  },
  {
    name: "one_to_two",
    label: "100 to 200 SAR",
    component: InputRedux,
    cellProps: { sm: 2, md: 2 },
    InputProps: { size: "small" },
  },
  {
    name: "two_to_three",
    label: "200 to 300 SAR",
    component: InputRedux,
    cellProps: { sm: 2, md: 2 },
    InputProps: { size: "small" },
  },
  {
    name: "three_to_four",
    label: "300 to 400 SAR",
    component: InputRedux,
    cellProps: { sm: 2, md: 2 },
    InputProps: { size: "small" },
  },
  {
    name: "more_than_four",
    label: "More than 400 SAR",
    component: InputRedux,
    cellProps: { sm: 2, md: 2 },
    InputProps: { size: "small" },
  },
];
