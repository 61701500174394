import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarForm from "./AddCarForm";
import UpdateCarForm from "./UpdateCarForm";
import CarService from "services/car.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";

export default function AddCar() {
  const { id } = useParams();
  const navigate = useNavigate();
  const car = useAppSelector((state) => state.car.car);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };
    data.availableBranchIds = values.availableBranchIds?.map(
      (branch: any) => branch.value
    );

    data.modelId = values.modelId;
    data.numberOfBags = Number(values.numberOfBags);
    data.numberOfDoors = Number(values.numberOfDoors);
    data.numberOfSeats = Number(values.numberOfSeats);
    data.manufacturingYear = Number(values.manufacturingYear);

    if (id) {
      CarService.updateCar(`${id}`, data, navigate);
    } else CarService.addCar(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && car ? `/car-details/${id}` : "/cars"}`}
          title={`Back to ${id && car ? "Car Details" : "Cars"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Car`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && car === "Not found" ? (
            <p>Car Not Found</p>
          ) : (
            <AddCarForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
