import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import InsuranceService from "services/insurance.service";
import AddInsuranceForm from "./AddInsuranceForm";
import UpdateInsuranceForm from "./UpdateInsuranceForm";

export default function AddInsurance() {
  const { id } = useParams();
  const navigate = useNavigate();
  const insurance = useAppSelector((state) => state.insurance.insurance);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    console.log(values);
    
    let data = { ...values };

    data.zero_to_one = Number(values?.zero_to_one);
    data.one_to_two = Number(values?.one_to_two);
    data.two_to_three = Number(values?.two_to_three);
    data.three_to_four = Number(values?.three_to_four);
    data.more_than_four = Number(values?.more_than_four);
    data.loginPlatform = "admin";
    delete data.phone;

    if (id) {
      InsuranceService.updateInsurance(`${id}`, data, navigate);
    } else InsuranceService.addInsurance(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && insurance ? `/insurance-details/${id}` : "/insurance"}`}
          title={`Back to ${id && insurance ? "Insurance Details" : "Insurances"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Insurance`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && insurance === "Not found" ? (
            <p>Insurance Not Found</p>
          ) : (
            <AddInsuranceForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateInsuranceForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
