import { dateRangeFilter, email } from "utils/validate.util";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import SelectSupplier from "components/organisms/SelectSupplier";
import SelectCar from "components/organisms/SelectCar";
import SelectBranch from "components/organisms/SelectBranch";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import SelectRedux from "components/molecules/SelectRedux";
import { E_ROLES } from "pages/AddUser/AddUserForm";
import SelectCompany from "components/organisms/SelectCompany";
import SelectFranchise from "components/organisms/SelectFranchise";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import { InsServiceOpts } from "pages/AddInsurance/AddInsuranceForm";

const formInputs: any = {
  docNumber: {
    name: "docNumber",
    label: "ID",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  isBusinessBooking: {
    name: "isBusinessBooking",
    label: "Business Booking",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  currentStatus: {
    name: "currentStatus",
    label: "Status",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "Active", value: "Active" },
        { label: "In Active", value: "In_Active" },
      ],
    },
  },
  isUnlimitedKM: {
    name: "isUnlimitedKM",
    label: "Unlimited KM",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  supplierId: {
    name: "supplierId",
    label: "Supplier",
    component: SelectSupplier,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  modelId: {
    name: "modelId",
    label: "Car",
    component: SelectCar,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  pickupBranchId: {
    name: "pickupBranchId",
    label: "Pickup Branch",
    component: SelectBranch,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  dropOffBranchId: {
    name: "dropOffBranchId",
    label: "Drop-Off Branch",
    component: SelectBranch,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  firstName: {
    name: "firstName",
    label: "First Name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  lastName: {
    name: "lastName",
    label: "Last Name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  email: {
    name: "email",
    label: "Email",
    validate: [email],
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { type: "email", size: "small" },
  },
  phoneNumber: {
    name: "phoneNumber",
    label: "Phone",
    component: PhoneInputRedux,
    cellProps: { sm: 6, md: 4 },
  },
  date: {
    name: "date",
    label: "Date",
    validate: [dateRangeFilter],
    component: DateRangePickerRedux,
    cellProps: { md: 8 },
    rowProps: { spacing: 0.5 },
    DateRangePickerProps: {
      onChange: () => { },
      InputFieldProps: {},
      value: { date: ["", ""], error: ["", ""] },
    },
    InputProps: { size: "small" },
  },
  countryOfResidence: {
    name: "countryOfResidence",
    label: "Country Of Residence",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  roles: {
    name: "roles",
    label: "Roles",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "User", value: E_ROLES.USER },
        { label: "Sales Manager", value: E_ROLES.COMFY_SALES_MANAGER },
        {
          label: "Operations Manager",
          value: E_ROLES.COMFY_OPERATIONS_MANAGER,
        },
        { label: "Supplier Admin", value: E_ROLES.SUPPLIER_ADMIN },
        {
          label: "Supplier Branch Manager",
          value: E_ROLES.SUPPLIER_BRANCH_MANAGER,
        },
        { label: "Company Admin", value: E_ROLES.COMPANY_ADMIN },
        {
          label: "Company Branch Manager",
          value: E_ROLES.COMPANY_BRANCH_MANAGER,
        },
      ],
    },
  },

  excludes: {
    name: "excludes",
    label: "Excludes",
    component: ComboBoxRedux,
    cellProps: { sm: 6, md: 4 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: true,
      options: InsServiceOpts,
    },
  },

  dateOfBirth: {
    name: "dateOfBirth",
    label: "Date of Birth",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
  },

  name: {
    name: "name",
    label: "Name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  manufacturer: {
    name: "manufacturer",
    label: "Manufacturer",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  acrissCode: {
    name: "acrissCode",
    label: "AcrissCode",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  priorityNumber: {
    name: "priorityNumber",
    label: "Priority Number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  discountType: {
    name: "discountType",
    label: "Discount Type",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "Fixed", value: "fixed" },
        { label: "Percentage", value: "percentage" },
      ],
    },
  },
  discountValue: {
    name: "discountValue",
    label: "Discount Value",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  priceType: {
    name: "priceType",
    label: "Price Type",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "Fixed Price", value: "fixed_price" },
        { label: "Fixed Percentage", value: "fixed_percentage" },
        {
          label: "Shared Percentage",
          value: "shared_percentage",
        },
      ],
    },
  },
  priceValue: {
    name: "priceValue",
    label: "Price Value",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  description: {
    name: "description",
    label: "Description",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  minOrderLimit: {
    name: "minOrderAmount",
    label: "Minimum Order Amount",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  emailSpecific: {
    name: "emailSpecific",
    label: "Email Specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  locationSpecific: {
    name: "locationSpecific",
    label: "Location Specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  modelSpecific: {
    name: "modelSpecific",
    label: "Model Specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  dateTimeSpecific: {
    name: "dateTimeSpecific",
    label: "Date Time Specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  isNewUserOnly: {
    name: "isNewUserOnly",
    label: "New User Only",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 8 },
  },

  dateOfIncorporation: {
    name: "dateOfIncorporation",
    label: "Incorporation Date",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  mainContactPerson: {
    name: "mainContactPerson",
    label: "Contact Person",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  registrationNumber: {
    name: "registrationNumber",
    label: "Registration number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  companyId: {
    name: "companyId",
    label: "Company",
    component: SelectCompany,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  franchiseCode: {
    name: "franchiseCode",
    label: "Franchise Code",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  dateOfEstablishment: {
    name: "dateOfEstablishment",
    label: "Establishment Date",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  employeeNumber: {
    name: "employeeNumber",
    label: "Employee Number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  franchiseId: {
    name: "franchiseId",
    label: "Franchise",
    component: SelectFranchise,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  designation: {
    name: "designation",
    label: "Designation",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  taxId: {
    name: "taxId",
    label: "Tax ID",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  branchCode: {
    name: "branchCode",
    label: "Branch Code",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  isAirConditioned: {
    name: "isAirConditioned",
    label: "Air Conditioned",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  dailyRate: {
    name: "dailyRate",
    label: "Daily Rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  weeklyRate: {
    name: "weeklyRate",
    label: "Weekly Rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  monthlyRate: {
    name: "monthlyRate",
    label: "Monthly Rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  manufacturingYear: {
    name: "manufacturingYear",
    label: "Manufacturing Year",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  extraKMCost: {
    name: "extraKMCost",
    label: "Extra KM Cost",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  freeKMPerDay: {
    name: "freeKMPerDay",
    label: "Free KM Per Day",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfSeats: {
    name: "numberOfSeats",
    label: "Number Of Seats",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfBags: {
    name: "numberOfBags",
    label: "Number Of Bags",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfDoors: {
    name: "numberOfDoors",
    label: "Number Of Doors",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  pageSize: {
    name: "pageSize",
    label: "Results per Page",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
};

export const fields = (keys: string[]): ReduxFormField[] => {
  return keys.map((key: string) => formInputs[key]) as ReduxFormField[];
};
