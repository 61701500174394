import { fields } from ".";
import { Field, change, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BranchService from "services/branch.service";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectSupplier from "components/organisms/SelectSupplier";
import CarModelService from "services/carModel.service";
import { carModelActions } from "redux/slices/carModel";
import { required } from "utils/validate.util";
import SelectCarModel from "components/organisms/SelectCarModel";

function AddCarForm({ handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const carModel = useAppSelector((state) => state.carModel.carModel);
  const branchesOptions = useAppSelector(
    (state) => state.branch.supplierBranches
  );

  const supplierId = useAppSelector(
    (state) => state.form?.["AddCarForm"]?.values?.supplierId
  );

  const carModelId = useAppSelector(
    (state) => state.form?.["AddCarForm"]?.values?.modelId
  );

  useEffect(() => {
    if (supplierId) BranchService.getSupplierBranches(supplierId);
  }, [supplierId]);

  useEffect(() => {
    if (carModelId) CarModelService.getCarModel(carModelId);
  }, [carModelId]);

  useEffect(() => {
    if (carModel) {
      dispatch(
        change("AddCarForm", "isAirConditioned", carModel?.isAirConditioned)
      );
      dispatch(change("AddCarForm", "numberOfSeats", carModel?.numberOfSeats));
      dispatch(change("AddCarForm", "numberOfBags", carModel?.numberOfBags));
      dispatch(change("AddCarForm", "numberOfDoors", carModel?.numberOfDoors));
      dispatch(change("AddCarForm", "transmission", carModel?.transmission));
    }
    return () => {
      dispatch(carModelActions.setCarModel(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carModel]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12}>
            <Field
              name="supplierId"
              label="Supplier"
              component={SelectSupplier}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="availableBranchIds"
              label="Available Branches"
              component={ComboBoxRedux}
              multiple={true}
              options={branchesOptions}
              disabled={branchesOptions.length < 1}
              placeholder={
                branchesOptions.length < 1
                  ? "Select a supplier to get branches"
                  : "Select branches"
              }
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="modelId"
              label="Car Model"
              validate= {required}
              component={SelectCarModel}
            />
          </Grid>
          <Grid item md={12}>
            <ReduxFormFields fields={fields} />
          </Grid>
        </Grid>

        <br />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddCarForm" })(AddCarForm);
