import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { carActions } from "redux/slices/car";

const url = "/api/non-api/car";

const CarService = {
  getCar: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const car = success.data;
      dispatch?.(carActions.setCar(car));
    } else dispatch?.(carActions.setCar({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCars: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(carActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(carActions.setCars({ cars: documents }));
      dispatch?.(carActions.setTotalCount({ totalCount }));
    }

    dispatch?.(carActions.setLoading(false));
    return [success, error];
  },

  getSupplierCars: async (id: string) => {
    const dispatch = getAppDispatch();

    dispatch?.(carActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?supplierId=${id}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(carActions.setSupplierCars({ cars: documents }));
      dispatch?.(carActions.setTotalCount({ totalCount }));
    }

    dispatch?.(carActions.setLoading(false));
    return [success, error];
  },

  addCar: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const car = success.data;
      dispatch?.(carActions.addCar(car));
      navigate?.("/cars");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCar: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const car = success.data;
      dispatch?.(carActions.setCar(car));
      navigate?.(`/car-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCar: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carActions.deleteCarById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarService;
