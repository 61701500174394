import { config } from "config";
import { SetCarsPayload, CarState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetPagePayload, SetTotalCountPayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: CarState = {
  cars: [],
  car: null,
  loading: true,
  current_filters: {},
  carOptions: [],
  supplierCars: [],
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {
    clear: (state) => {
      state.carOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCar: (state, action) => {
      state.cars.unshift(action.payload);
    },
    updateCar: (state, action) => {
      const { id, car } = action.payload;
      state.cars.every(({ _id }, i) => {
        if (id === _id) {
          state.cars[i] = car;
          return false;
        }
        return true;
      });
    },
    deleteCarById: (state, action) => {
      const id = action.payload;
      state.cars.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.cars.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCar: (state, action) => {
      state.car = action.payload;
    },
    setCars: (state, action: PayloadAction<SetCarsPayload>) => {
      const { cars } = action.payload;
      let options: SelectOption[] = [];

      cars.forEach(({ _id, modelId }: any) => {
        const option = { value: _id, label: `${modelId.name}` };
        options.push(option);
      });

      state.cars = cars;
      state.carOptions = options;
    },

    setSupplierCars: (state, action: PayloadAction<SetCarsPayload>) => {
      const { cars } = action.payload;
      let options: SelectOption[] = [];
      cars.forEach(({ modelId }: any) => {
        const option = { value: modelId._id, label: modelId.name };
        options.push(option);
      });
      state.supplierCars = options;
    },

    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },

    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carReducer = carSlice.reducer;

export const carActions = carSlice.actions;
export default carReducer;
