import { useEffect } from "react";
import SupplierService from "services/supplier.service";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function SelectSupplier(props: any) {
	// const { id } = useParams();
	const dispatch = useAppDispatch();
	const supplierNames = useAppSelector(
		(state) => state.supplier.supplierOptions
	);

	useEffect(() => {
		SupplierService.getSuppliers({});

		return () => {
			dispatch(supplierActions.clear());
		};
	}, [dispatch]);

	return (
		<SelectRedux
			{...props}
			options={supplierNames}
		/>
	);
}
