import { Container, Stack, Grid } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./PromotionDetails.module.css";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import PromotionService from "services/promotion.service";
import { promotionActions } from "redux/slices/promotion";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";

export default function PromotionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotion = useAppSelector((state) => state.promotion.promotion);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    PromotionService.getPromotion(id || "");
    return () => {
      dispatch(promotionActions.setPromotion(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/promotions" title="Back to promotion" />
      <PageHeader title="promotion details" />
      {loading && <CircleLoader />}
      {id && !promotion ? (
        <p>Promotion Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={promotion?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-promotion/${promotion._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Promotion ID</label>
                <p>{promotion?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Title</label>
                <p>{promotion?.title}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Description</label>
                <p>{promotion?.description}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Discount Type</label>
                <p>{promotion?.discountType}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Discount Value</label>
                <p>{promotion?.discountValue?.toFixed(2)}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Minimum Order Amount</label>
                <p>{promotion?.minOrderAmount?.toFixed(2)}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
