import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectCarPrice from "components/organisms/SelectCarPrice";

export { default } from "./AddCarForm";

export const fields: ReduxFormField[] = [
  {
    name: "carPricingId",
    label: "Car Pricing",
    validate: [required],
    component: SelectCarPrice,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "manufacturingYear",
    label: "Manufacturing Year",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "isAirConditioned",
    label: "Air Conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
    CheckBoxProps: {
      disabled: true,
    },
  },
  {
    name: "fuelType",
    label: "Fuel Type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "petrol", label: "Petrol" },
        { value: "electric", label: "Electric" },
        { value: "hybrid", label: "Hybrid" },
      ],
    },
  },
  {
    name: "transmission",
    label: "Transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "Auto" },
        { value: "Manual", label: "Manual" },
      ],
      disabled: true,
    },
  },
  {
    name: "numberOfSeats",
    label: "Number Of Seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
    InputProps: {
      disabled: true,
    },
  },
  {
    name: "numberOfBags",
    label: "Number Of Bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
    InputProps: {
      disabled: true,
    },
  },
  {
    name: "numberOfDoors",
    label: "Number Of Doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
    InputProps: {
      disabled: true,
    },
  },
];
