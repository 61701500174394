import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarModelForm from "./AddCarModelForm";
import UpdateCarModelForm from "./UpdateCarModelForm";
import CarModelService from "services/carModel.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";

export default function AddCarModel() {
  const { id } = useParams();
  const navigate = useNavigate();
  const carModel = useAppSelector((state) => state.carModel.carModel);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };

    data.numberOfBags = Number(values.numberOfBags);
    data.numberOfDoors = Number(values.numberOfDoors);
    data.numberOfSeats = Number(values.numberOfSeats);

    if (id) {
      CarModelService.updateCarModel(`${id}`, data, navigate);
    } else CarModelService.addCarModel(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${
            id && carModel ? `/car-model-details/${id}` : "/car-models"
          }`}
          title={`Back to ${id && carModel ? "Model Details" : "Car Models"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Model`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && carModel === "Not found" ? (
            <p>Car Model Not Found</p>
          ) : (
            <AddCarModelForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarModelForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
