import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { config } from "config";
import { promotionActions } from "redux/slices/promotion";
import PromotionService from "services/promotion.service";
import PromotionFilters from "../Filters/PromotionFilters";

export default function PromotionList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const promotions = useAppSelector((state) => state.promotion.documents);
  const loading = useAppSelector((state) => state.promotion.loading);
  const filters = useAppSelector((state) => state.promotion.filters);
  const refresh = useAppSelector((state) => state.promotion.refresh);
  const totalCount = useAppSelector((state) => state.promotion.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(promotionActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    PromotionService.getPromotions(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <PromotionFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={6}
              loading={loading}
              length={promotions.length < 1 && loading ? 0 : promotions.length}
              message="No promotions to show"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Minimum Order Amount</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promotions.map((promotion, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{promotion?.docNumber}</StyledTableCell>
                    <StyledTableCell>{promotion?.title}</StyledTableCell>
                    <StyledTableCell>
                      {promotion.discountType?.toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell>
                      {promotion?.discountValue?.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {promotion?.minOrderAmount?.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/promotion-details/${promotion._id}`}
                      >
                        Details
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {/* {!refreshLoader && showFilters && ( */}
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) =>
                dispatch(promotionActions.setPage({ page }))
              }
            />
          </Stack>
          {/* )} */}
        </Box>
      </TableContainer>
    </>
  );
}
