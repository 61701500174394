export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
	HANDLE_STATUS = "HANDLE_STATUS",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	MARKET_TRANSFER = "MARKET_TRANSFER",
	CONFIRMATION_FORM = "CONFIRMATION_FORM",
	TICKET_ADD_COMMENT = "TICKET_ADD_COMMENT",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
	data: any;
	width: any;
	type: ModalType;
	loading?: boolean;
}

export type ModalState = OpenModalState & {
	open: boolean;
};
