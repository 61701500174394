import { useEffect } from "react";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarService from "services/car.service";
import { carActions } from "redux/slices/car";

export default function SelectCar(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const cars = useAppSelector((state) => state.car.cars);
  const supplierCars = useAppSelector((state) => state.car.supplierCars);

  const supplierId = useAppSelector(
    (state) => state.form?.["BookingFiltersForm"]?.values?.supplierId
  );
  const loading = useAppSelector((state) => state.car.loading);
  const options = cars.map((car) => ({
    value: car.modelId._id,
    label: car.modelId.name,
  }));

  useEffect(() => {
    if (supplierId) {
      CarService.getSupplierCars(supplierId);
    } else {
      CarService.getCars({});
    }
    return () => {
      dispatch(carActions.clear());
    };
  }, [dispatch, supplierId]);

  return (
    <SelectRedux
      {...props}
      options={supplierId ? supplierCars : options}
      disabled={loading ? true : false}
    />
  );
}
